import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import useContentfulAssets from '../../hooks/useContentfulAssets';
import { useContentfulImages } from '../../hooks/useContentfulImages';
import React from 'react';
import isExternalUrl, {
  addTrailingSlash,
  isExternalUrlhref,
  isYoutubeLink,
} from '../../utils';
import * as Styled from './imageWithPathStyle';

const ImageWithPath = ({ sectionData }) => {
  const optionsMainStyle = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <Styled.ParaText>{children}</Styled.ParaText>
      ),
      [BLOCKS.HEADING_3]: (node, children) => (
        <Styled.HeaderH3>{children}</Styled.HeaderH3>
      ),
      [INLINES.HYPERLINK]: (node, children) =>
        isYoutubeLink(node.data.uri) ? (
          <Styled.VideoContainer>
            <Styled.Iframe src={node.data.uri} frameborder="0"></Styled.Iframe>
          </Styled.VideoContainer>
        ) : (
          <Styled.AnchorStyle
            href={
              isExternalUrlhref(node?.data?.uri)
                ? node?.data?.uri
                : addTrailingSlash(
                    process.env.GATSBY_DOMAIN_URL + node?.data?.uri
                  )
            }
            target={isExternalUrl(node?.data?.uri) ? '_blank' : '_self'}
          >
            {children}
          </Styled.AnchorStyle>
        ),
      [INLINES.ASSET_HYPERLINK]: (node, children) => {
        const asset = useContentfulAssets(node?.data?.target?.sys?.id);
        if (asset) {
          return (
            <Styled.AnchorStyle href={asset?.node?.file?.url} target={'_blank'}>
              {children}
            </Styled.AnchorStyle>
          );
        }
      },
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const asset = useContentfulImages(node.data.target.sys.id);
        return asset.node.file.url.includes('image') ? (
          asset?.node?.description ? (
            <Styled.AnchorStyle
              href={
                isExternalUrlhref(asset?.node?.description)
                  ? asset?.node?.description
                  : addTrailingSlash(
                      process.env.GATSBY_DOMAIN_URL + asset?.node?.description
                    )
              }
              target={
                isExternalUrl(asset?.node?.description) ? '_blank' : '_self'
              }
            >
              <Styled.ImageStyle
                src={asset.node.file.url}
                alt={asset.node.title}
              />
            </Styled.AnchorStyle>
          ) : (
            <Styled.ImageStyle
              src={asset.node.file.url}
              alt={asset.node.title}
            />
          )
        ) : (
          <></>
        );
      },
    },
    renderText: (text) =>
      text.split('\n').flatMap((text, i) => [i > 0 && <br key={i} />, text]),
  };

  return (
    <React.Fragment>
      {sectionData?.richText &&
        documentToReactComponents(
          JSON.parse(sectionData?.richText?.raw),
          optionsMainStyle
        )}
      <Styled.AnchorStyle
        href={
          isExternalUrlhref(sectionData?.path)
            ? sectionData?.path
            : addTrailingSlash(
                process.env.GATSBY_DOMAIN_URL + sectionData?.path
              )
        }
        target={isExternalUrl(sectionData?.path) ? '_blank' : '_self'}
      >
        <Styled.ImageStyle
          src={sectionData?.image?.file?.url}
          alt={sectionData?.image?.file?.fileName}
        ></Styled.ImageStyle>
      </Styled.AnchorStyle>
      <div style={{ clear: 'both' }}></div>
    </React.Fragment>
  );
};

export default ImageWithPath;
